#acc-alert {
	background: white;
}

#acc-alert-buttons {
	.acc--acceptLink {
		background-color: #d71920;
		border-bottom: none;

		&:hover {
			background-color: darken(#d71920, 10%);
		}
	}

	.acc--denyLink {
		border-bottom: none;
	}
}

#acc-alert-buttons a {
	text-shadow: none;
}
