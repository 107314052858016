$button-primary-background-color: $color-primary !default;
$button-primary-background-color-dark: $color-primary-dark !default;
$button-box-shadow: none !default;

%button {
	@extend %master-button;
	font-size: 1.6rem;
	min-height: 50px; // min-height au lieu de height car le texte du bouton peut etre sur plusieurs lignes
	width: 100%;
	padding: 0;
	appearance: none;
	border: none;
	text-decoration: none;
	outline: 0;
	box-sizing: border-box;
	cursor: pointer;
	text-align: center;
}

%button--primary {
	@extend %button;
	@extend %font-bold;
	background: $button-primary-background-color;
	color: white;
	box-shadow: $button-box-shadow;

	@include gridle_state(md) {
		&:hover,
		&:focus {
			background: $button-primary-background-color-dark;
		}
	}
}

%button--secondary {
	@extend %button;
	@extend %font-bold;
	background: white;
	color: $black;
	border: 1px solid $black;
	min-height: 40px;

	@include gridle_state(md) {
		&:hover,
		&:focus {
			box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1); // box shadow small
		}
	}
}

%button--facebook {
	@extend %button;
	background: white;
	color: #3c4043;
	border-radius: 4px;
	box-shadow: none;
	font-size: 1.6rem;
	text-transform: initial;
	font-weight: unset;
}

%button--loading {
	@extend %button;
	@extend %font-bold;
	background: $grey-light;
	color: $grey-dark;
	cursor: wait;
}

%button--disabled {
	@extend %button;
	@extend %font-bold;
	opacity: 0.2;
	pointer-events: none;
	cursor: not-allowed;
}
