@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/gridle";@import "/builds/perfectstay/marketing/front/src/app/pages/.styles/colors";
.shop-switcher {
	display: flex;
	flex-direction: column;

	&__button {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 6px;
		height: 32px;

		.icon:first-of-type {
			width: 28px;
			height: 28px;
			margin-right: 8px;
		}
	}

	&__body {
		background: white;
		min-width: 375px;
		border-radius: $border-radius-small;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); // box-shadow small
		z-index: 10;

		.shop-list {
			&__item {
				min-width: 375px;
				box-sizing: border-box;
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 56px;
				padding: 16px;
				border-bottom: 1px solid var(--color-gray-500, $grey-medium);

				&:last-of-type {
					border-bottom: none;
				}

				.icon {
					width: 24px;
					height: 24px;
					margin-right: 16px;
					transition: transform 0.3s ease;
				}

				&:hover {
					cursor: pointer;
					background: var(--color-gray-300, $grey-light);
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1); // box-shadow xsmall

					.icon {
						transform: scale(1.2, 1.2);
					}
				}
			}

			&__flag {
				display: flex;
				align-content: center;
			}
		}
	}
}
